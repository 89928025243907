import axios from 'axios';
import { FC, createContext, useContext, useEffect, useState } from 'react';

type AuthContext = {
    token: string | null;
    setTokenHandler: (newToken: string | null) => void;
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AuthContext = createContext({} as AuthContext);

const AuthProvider: FC = ({ children }) => {
    // State to hold the authentication token
    const [token, setToken] = useState(localStorage.getItem('token'));

    useEffect(() => {
        if (token) {
            axios.defaults.headers.common.Authorization = 'Bearer ' + token;
            localStorage.setItem('token', token);
        } else {
            delete axios.defaults.headers.common.Authorization;
            localStorage.removeItem('token');
        }
    }, [token]);

    return (
        <AuthContext.Provider
            value={{
                token,
                setTokenHandler(newToken) {
                    setToken(newToken);
                }
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    return useContext(AuthContext);
};

export default AuthProvider;
