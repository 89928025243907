import { Logout } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import { Button, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { CSSObject, Theme, styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import { useAuth } from 'src/contexts/AuthContext';
import { UserSession } from 'src/data/local/UserSession';
import { sidebarListItems } from 'src/mock/SidebarListItems';
import { Each } from '../shared/Each';

const drawerWidth = 250;

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
    }),
    overflowX: 'hidden'
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(6)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(10)} + 1px)`
    }
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#fff',
    borderRight: '0px solid #fff',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar
}));

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
    width: drawerWidth,
    background: '#E6E6E6',
    flexShrink: 0,
    borderColor: '#f6f6f6',
    whiteSpace: 'nowrap',
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme)
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme)
    })
}));

export default function SidebarView() {
    const { setTokenHandler } = useAuth();
    const logout = () => {
        UserSession.getInstance().clear();
        sessionStorage.setItem('selectedOption', '');
        setTokenHandler(null);
    };
    const [open, setOpen] = useState(true);
    const [isSelected, setIsSelected] = useState('');
    const role = UserSession.getInstance().getUser().role;

    const handleDrawer = () => {
        setOpen(!open);
    };

    useEffect(() => {
        const storedSelectedOption = sessionStorage.getItem('selectedOption');
        setIsSelected(storedSelectedOption);
    }, []);

    const handleSelected = (selected: string) => {
        setIsSelected(selected);
        sessionStorage.setItem('selectedOption', selected);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                height: '100vh'
            }}
        >
            <CssBaseline />
            <Drawer variant="permanent" open={open}>
                <DrawerHeader>
                    <IconButton onClick={handleDrawer}>
                        {<MenuIcon />}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <List>
                    <Each
                        of={sidebarListItems}
                        render={(item, index) =>
                            item.role.includes(role) && (
                                <Tooltip
                                    key={index}
                                    title={open ? '' : item.title}
                                    placement={'right'}
                                >
                                    <ListItem
                                        selected={isSelected === item.path}
                                        disablePadding
                                        sx={{
                                            display: 'block',
                                            marginTop: 0.5,
                                            marginBottom: 0.5,
                                            ':active': {
                                                backgroundColor: 'red',
                                                color: '#fff'
                                            }
                                        }}
                                    >
                                        <Button
                                            onClick={() =>
                                                handleSelected(item.path)
                                            }
                                            disableRipple
                                            component={RouterLink}
                                            to={item.path}
                                            sx={{
                                                width: '100%',
                                                minHeight: 48,
                                                justifyContent: open
                                                    ? 'initial'
                                                    : 'center',
                                                px: 2.5
                                            }}
                                        >
                                            <ListItemIcon
                                                sx={{
                                                    minWidth: 0,
                                                    mr: open ? 3 : 'auto',
                                                    justifyContent: 'center'
                                                }}
                                            >
                                                {item.icon}
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={item.title}
                                                sx={{
                                                    opacity: open ? 1 : 0,
                                                    fontWeight: 'bold'
                                                }}
                                            />
                                        </Button>
                                    </ListItem>
                                </Tooltip>
                            )
                        }
                    />
                </List>
                <Divider />
                <Tooltip
                    title={open ? '' : 'Cerrar Sesión'}
                    placement={'right'}
                >
                    <Box
                        sx={{
                            position: 'absolute',
                            bottom: 0,
                            left: 0,
                            display: 'block'
                        }}
                    >
                        <Button
                            onClick={logout}
                            disableRipple
                            component={RouterLink}
                            to="/sign-in"
                            sx={{
                                width: '100%',
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5
                            }}
                        >
                            <ListItemIcon
                                onClick={() => {
                                    UserSession.getInstance().clear();
                                }}
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center'
                                }}
                            >
                                <Logout />
                            </ListItemIcon>
                            <ListItemText
                                primary={'Cerrar Sesión'}
                                sx={{
                                    width: '100%',
                                    opacity: open ? 1 : 0
                                }}
                            />
                        </Button>
                    </Box>
                </Tooltip>
            </Drawer>
        </Box>
    );
}
