import { DashboardRepository } from '../data/DashboardRepository';

export class GetGuidesReport {
    constructor(private readonly repository: DashboardRepository) {}

    async execute(channel: string) {
        const result = await this.repository.getGuidesReports(channel);

        return result;
    }
}
