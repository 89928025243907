import { IUserSession } from './../../auth/domain/models/IUserSession';
export class UserSession {
    private static instance: UserSession;

    public static getInstance(): UserSession {
        if (!UserSession.instance) {
            UserSession.instance = new UserSession();
        }

        return UserSession.instance;
    }

    public setToken(token: string) {
        localStorage.setItem('token', token);
    }

    public getToken() {
        return localStorage.getItem('token');
    }

    public setUser(user: IUserSession) {
        localStorage.setItem('user', JSON.stringify(user));
    }

    public getUser(): IUserSession {
        return JSON.parse(localStorage.getItem('user'));
    }

    public clear() {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        localStorage.removeItem('pagination');
    }
}
