import { WarehouseOutlined } from '@mui/icons-material';
import { Box, LinearProgress, Typography } from '@mui/material';
import { UserSession } from 'src/data/local/UserSession';
import DashboardCardItem from './DashboardCardItem';

const OrdersDashboard = () => {
    /* const BorderLinearProgress = styled(LinearProgress)=>({
        height: 10,
        borderRadius: 5,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: 200
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5,
            backgroundColor: '#1a90ff',
        }
    });*/

    const userSession = UserSession.getInstance().getUser();
    return (
        <div>
            <Box>
                <Typography variant="h2"> Hola {userSession.name}, </Typography>
                <Typography variant="subtitle1" fontSize={16}>
                    {' '}
                    Tu reporte de órdenes está aquí.{' '}
                </Typography>
            </Box>
            <Box
                sx={{
                    mt: 2,
                    display: 'grid',
                    gridTemplateColumns: 'repeat(4, 1fr)',
                    gap: 2,
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <DashboardCardItem
                    title="126K"
                    subtitle="Total Orders"
                    icon={
                        <WarehouseOutlined color="primary" fontSize="large" />
                    }
                />
                <DashboardCardItem
                    title="126K"
                    subtitle="Total Orders"
                    icon={
                        <WarehouseOutlined color="primary" fontSize="large" />
                    }
                />
                <DashboardCardItem
                    title="126K"
                    subtitle="Total Orders"
                    icon={
                        <WarehouseOutlined color="primary" fontSize="large" />
                    }
                />
                <DashboardCardItem
                    title="126K"
                    subtitle="Total Orders"
                    icon={
                        <WarehouseOutlined color="primary" fontSize="large" />
                    }
                />
            </Box>
            <Box
                sx={{
                    mt: 5,
                    display: 'flex',
                    direction: 'row'
                }}
            >
                <Typography variant="subtitle1" fontSize={16}>
                    HOLA
                </Typography>
                <LinearProgress
                    variant="determinate"
                    color="success"
                    valueBuffer={100}
                    value={50}
                    sx={{
                        width: 300,
                        height: 10,
                        borderRadius: 5
                    }}
                />
            </Box>
        </div>
    );
};

export default OrdersDashboard;
