import { IResponse } from 'src/core/domain/models/BaseResponse';
import { UserSession } from 'src/data/local/UserSession';
import { IUser } from 'src/login/presentation/models/IUser';
import api from './api';

class AuthService {
    private path = '/auth';

    async signIn(email, password): Promise<IResponse<IUser>> {
        return api
            .post(`${this.path}/signin`, {
                email,
                password
            })
            .then((response) => {
                return response.data;
            });
    }

    async pmmLogin() {
        const token = UserSession.getInstance().getToken();
        return api
            .get(`${this.path}/pmm/login`, {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            })
            .then((response) => {
                return response.data;
            });
    }

    async paqueteExpressLogin() {
        const token = UserSession.getInstance().getToken();
        return api
            .get(`${this.path}/pqexpress/login`, {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            })
            .then((response) => {
                return response.data;
            });
    }
}

export default AuthService;
