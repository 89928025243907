import AuthService from 'src/services/AuthService';
import { IUserSession } from '../../../auth/domain/models/IUserSession';
import { UserSession } from './../../../data/local/UserSession';

export default class LoginRepository {
    constructor(private readonly authService: AuthService) {}

    async signIn(email: string, password: string) {
        const result = await this.authService.signIn(email, password);

        const token = result.data.token;
        const user: IUserSession = {
            name: result.data.name,
            email: result.data.email,
            role: result.data.roleId
        };
        const userSession = UserSession.getInstance();
        userSession.setUser(user);
        userSession.setToken(token);
        return result;
    }
}
