import axios from 'axios';
import { UserSession } from 'src/data/local/UserSession';
const baseURL =
    process.env.NODE_ENV === 'production'
        ? '/api/v1'
        : 'http://localhost:4001/v1';
const instance = axios.create({ baseURL });

instance.interceptors.request.use((request) => {
    if (UserSession.getInstance().getToken()) {
        request.headers.Authorization = `Bearer ${UserSession.getInstance().getToken()}`;
    }
    return request;
});

instance.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response.status === 401) {
            UserSession.getInstance().clear();
            window.location.href = '/sign-in';
            return Promise.reject();
        }
        return Promise.reject(error);
    }
);

export default instance;
