import { GuideReportData } from '../models/GuideReportData';
import { GuidesReportResponse } from './../../data/models/response/GuidesReportResponse';
export function GetGuidesReportsMapper(
    response: GuidesReportResponse
): GuideReportData {
    const labels: string[] = [];
    const notPrinted: number[] = [];
    const printed: number[] = [];
    const notGuides: number[] = [];
    const cancelled: number[] = [];

    for (const item of response.data) {
        labels.push(item.date);
        notPrinted.push(item.guides.notPrinted);
        printed.push(item.guides.printed);
        notGuides.push(item.guides.noGuides);
        cancelled.push(item.guides.cancelled);
    }

    return {
        labels,
        notPrinted,
        printed,
        notGuides,
        cancelled
    };
}
