import { ThemeProvider } from '@mui/material';
import { StylesProvider } from '@mui/styles';
import React, { useState } from 'react';
import { themeCreator } from './base';

export const ThemeContext = React.createContext(function handleTheme(
    themeName: string
): string {
    return themeName;
});

const ThemeProviderWrapper: React.FC = (props) => {
    const curThemeName = localStorage.getItem('appTheme') || 'PureLightTheme';
    const [themeName, setThemeName] = useState(curThemeName);
    const theme = themeCreator(themeName);

    const setThemeNameHandler = (name: string) => {
        localStorage.setItem('appTheme', name);
        setThemeName(name);
        return name;
    };

    return (
        <StylesProvider injectFirst>
            <ThemeContext.Provider value={setThemeNameHandler}>
                <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
            </ThemeContext.Provider>
        </StylesProvider>
    );
};

export default ThemeProviderWrapper;
