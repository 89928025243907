import { Box, Container, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';

import { styled } from '@mui/material/styles';

const MainContent = styled(Box)(
    ({ theme }) => `
    height: 100%;
    display: flex;
    flex: 1;
    overflow: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: ${theme.spacing(4)};
`
);

const TypographyH1 = styled(Typography)(
    ({ theme }) => `
  font-size: ${theme.typography.pxToRem(75)};
`
);

const TypographyH3 = styled(Typography)(
    ({ theme }) => `
  color: ${theme.colors.alpha.black[50]};
`
);

interface ComingSoonPageProps {
    pageName?: string;
    relaseDate?: string;
}
// const PageTitleWrapper: FC<PageTitleWrapperProps> = ({ children }) =>
function ComingSoonPage({
    pageName,
    relaseDate
}: Readonly<ComingSoonPageProps>) {
    const calculateTimeLeft = (newRelaseDate: string = '2024') => {
        const difference = +new Date(newRelaseDate) - +new Date();
        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60)
            };
        }

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        setTimeout(() => {
            setTimeLeft(calculateTimeLeft(relaseDate));
        }, 1000);
    });

    const timerComponents = [];

    Object.keys(timeLeft).forEach((interval, index) => {
        if (!timeLeft[interval]) {
            return;
        }

        timerComponents.push(
            <Box textAlign="center" key={`timer${index}`} px={3}>
                <TypographyH1 variant="h1">{timeLeft[interval]}</TypographyH1>
                <TypographyH3 variant="h3">{interval}</TypographyH3>
            </Box>
        );
    });

    return (
        <>
            <Helmet>
                <title>{pageName ? ` ${pageName} - ` : ''} Coming Soon</title>
            </Helmet>
            <MainContent>
                <Container maxWidth="md">
                    <Box textAlign="center" mb={3}>
                        <Container maxWidth="xs">
                            <Typography variant="h1" sx={{ mt: 4, mb: 2 }}>
                                {pageName ? (
                                    <>
                                        {pageName} <br />
                                    </>
                                ) : (
                                    ''
                                )}{' '}
                                Coming Soon
                            </Typography>
                            <Typography
                                variant="h3"
                                color="text.secondary"
                                fontWeight="normal"
                                sx={{ mb: 4 }}
                            >
                                We're working on implementing the last features
                                before our launch!
                            </Typography>
                        </Container>
                        <img
                            alt="Coming Soon"
                            height={200}
                            src="/static/images/status/coming-soon.svg"
                        />
                    </Box>

                    <Box display="flex" justifyContent="center">
                        {timerComponents.length ? (
                            timerComponents
                        ) : (
                            <>Time's up!</>
                        )}
                    </Box>
                </Container>
            </MainContent>
        </>
    );
}

export default ComingSoonPage;
