import { Avatar, Box, Typography } from '@mui/material';

import { styled } from '@mui/material/styles';
import { UserSession } from 'src/data/local/UserSession';

const UserBoxText = styled(Box)(
    ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = styled(Typography)(
    ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
`
);

function HeaderUserbox() {
    const userSession = UserSession.getInstance().getUser();
    function stringToColor(string: string) {
        let hash = 0;
        let i;

        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.substring(-2);
        }
        /* eslint-enable no-bitwise */

        return color;
    }

    function stringAvatar(name: string) {
        return {
            sx: {
                bgcolor: stringToColor(name)
            },
            children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`
        };
    }

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', mt: 1, mr: 3 }}>
            {/* <Avatar variant="rounded" alt={user.name} src={user.avatar} /> */}
            <Avatar {...stringAvatar(userSession.name)} />
            <UserBoxText>
                <UserBoxLabel variant="body1">{userSession.name}</UserBoxLabel>
            </UserBoxText>
        </Box>
    );
}

export default HeaderUserbox;
