import axios from 'axios';
import { UserSession } from '../local/UserSession';
const baseURL =
    process.env.NODE_ENV === 'production'
        ? '/api/v1'
        : 'http://localhost:4001/v1';
const instance = axios.create({ baseURL });
instance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        // Anything except 2XX goes to here
        const status = error.response?.status || 500;
        if (status === 401) {
            delete instance.defaults.headers.common.Authorization;
            UserSession.getInstance().clear();
            window.location.href = '/sign-in';
            return Promise.reject(error);
        } else {
            return Promise.reject(error); // Delegate error to calling side
        }
    }
);
export default instance;
