import { Box, Card, Typography } from '@mui/material';

interface Props {
    title: string;
    subtitle: string;
    icon: JSX.Element;
}
const DashboardCardItem = ({ title, subtitle, icon }: Props) => {
    return (
        <Card
            sx={{
                display: 'flex',
                p: 5
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: 100,
                    height: 100,
                    borderRadius: '50%',
                    backgroundColor: '#fef3f1',
                    p: 1
                }}
            >
                {icon}
            </Box>
            <Box
                sx={{
                    ml: 2,
                    mt: 1
                }}
            >
                <Typography variant="h2"> {title} </Typography>
                <Typography variant="subtitle1" fontSize={16}>
                    {subtitle}
                </Typography>
            </Box>
        </Card>
    );
};

export default DashboardCardItem;
