import LoginRepository from '../data/repository/LoginRepository';

export default class LoginUseCase {
    constructor(private readonly repository: LoginRepository) {}

    async execute(email: string, password: string) {
        const result = await this.repository.signIn(email, password);
        return result;
    }
}
